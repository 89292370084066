import React ,{useState, useEffect} from "react";
import Stats from "./Stats";
import UpsellTable from "./UpsellTable";
import "./dashboard.scss";
import {
Grid,
Divider, 
Card,
CardContent,
} from "@material-ui/core";
import { dateformat, universaldate } from "../../utils/dateFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../../components/controls/Loading";
import {getEventDashboardByAccountId} from '../../services/eventService';
import EventsFilter from "./EventsFilter";
import NoEventsFound from "../../components/controls/NoEventsFound";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredEventDetails } from "../../redux/actions/eventActions";

const EventsOverview = (props) => {
  const dispatch = useDispatch();
  const { setOpenPopup, id, defaultPageLength } = props; 
  const filterData = useSelector(state => state.eventReducer.filterData);
  const filteredEventDetails = useSelector(state => state.eventReducer.filteredEventDetails);
  const [count,setCount]=useState(2);
  const [hasMore,setHasMore]=useState(true);

   useEffect(() => {
    setCount(2);
    setHasMore(true);
    return ()=>{
      //un-mounted;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData.selectedEventName, filterData.selectedEventTime, filterData.selectedDate]);

  const fetchMoreData = () => {
    const bodyData = { "accountid" : Number(id) }
    if (filterData.selectedEventName) bodyData['eventname'] = filterData.selectedEventName;
    if (filterData.selectedEventTime) bodyData['time'] = filterData.selectedEventTime;
    if (filterData.selectedDate) bodyData['date'] = filterData.selectedDate;

      getEventDashboardByAccountId(bodyData, defaultPageLength, count)
      .then((res) => { 
        for(let a=0 ;a<res.data.length;a++){
          res.data[a].customerdateformat=universaldate(res.data[a].eventdatetime);
        }
        if (res.data.length > 0) { 
          dispatch(setFilteredEventDetails(filteredEventDetails.concat(res.data)));
          setCount(count+1);
          setHasMore(res.data.length >= 10);
        } else {
          setHasMore(false); 
        }
      }).catch((error)=>{
        setHasMore(false);
      })
  };

      return (
          <div className="event-overview">

          <EventsFilter id={id} defaultPageLength={defaultPageLength} />

          <Divider className="my-2" />

          <div id="scrollableDiv" style={{ height: 360, overflow: "auto" }}> 
            <InfiniteScroll
                dataLength={filteredEventDetails.length}
                next={fetchMoreData}
                hasMore={filteredEventDetails?.length >= 10 && hasMore}
                loader={<Loading/>}
                scrollableTarget="scrollableDiv"
                endMessage={
                <p style={{ textAlign: "center" }}>
                    <br/>
                    <b>Yay! You have seen it all</b>
                </p>
                }
            >
                <Grid container spacing={2}>
                  {filteredEventDetails.length > 0 ? (filteredEventDetails.map((events,index) => (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl key={index}>
                        <Card>
                            <CardContent className="tile-title">
                                <Stats
                                eventTitle={events.eventname}
                                status={events.onsaleflag===1}
                                revenue={events.totalEventCost} 
                                customerdateformat={events.customerdateformat}
                                soldTickets={events.soldcount+'/'+events.numberoftickets}
                                reservedTickets={events.reservedcount}
                                currencytype={events.currencytype}
                                id={events.eventid}
                                eventdetailid={events.eventdetailid}
                                eventid={events.eventid}
                                accountid = {events.accountid}
                                disable = {dateformat(events.dashboardEventdatetime) - new Date() >= 0 ? true : false}
                                setPopup = {setOpenPopup}
                                event_subcategory = {events.event_subcategory}
                                />
                                <UpsellTable upsellDetails={events.upsellList} currencytype={events.currencytype} eventimage={events.eventimage} />
                            </CardContent>
                        </Card>
                    </Grid>
                  ))
                ) : (
                    <Grid container alignContent="center" justifyContent="center" alignItems="center">
                        <NoEventsFound />
                    </Grid>
                )}
                </Grid>
              </InfiniteScroll>
          </div>
      </div>

  );
  }

export default EventsOverview;