import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import User from "../pages/users/User";
import CustomerAccount from "../pages/events/CustomerAccount";
import EventList from "../pages/events/EventList";
import EventForm from "../pages/events/EventForm";
import VenueCreations from "../pages/events/VenueCreations";
import TaxConfig from "../pages/events/TaxConfig";
import Login from "../components/auth/Login";
import Page404 from "../pages/Page404";
import UserProfile from "../pages/users/UserProfile";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import AuthRouter from "../helper/AuthRouter";
import ChangePassword from "../pages/users/ChangePassword";
import { PrivateRouter } from "../helper/PrivateRouter";
import UpsellList from "../pages/events/UpsellList";
import UpsellCreation from "../pages/events/UpsellCreation";
import DefaultDashboard from "../pages/dashboard/DefaultDashboard";
import QrValidation from "../pages/events/QrValidation";
import DashboardReport from "../pages/dashboard-report/index";
import Order from "../pages/order/Order";
import OrderDetails from "../pages/order/OrderDetails";
import ExchangeTicket from "../pages/order/ExchangeTicket";
import ClientApi from "../pages/events/ClientApiList";
import TicketTemplate from "../pages/template/TicketTemplate";
import EmailTemplate from "../pages/template/EmailTemplate";
import Branding from "../pages/events/Branding";
import PromoCodeList from "../pages/promo-code/PromoCodeList";
import PromoCode from "../pages/promo-code/PromoCode";
import AllotmentList from "../pages/Allotment/AllotmentList";
import Allotment from "../pages/Allotment/Allotment";
import PerformanceView from "../pages/PerformanceView";
import TicketTypes from "../pages/ticket-type/TicketTypes";
import TicketTypeConfiguration from "../pages/ticket-type/TicketTypeForm";
import ReportViewer from "../pages/reports/ReportViewer";

 
const Routing = () => {
  
  return (
    <Router>
      <Switch>
      <AuthRouter path="/ResetPassword" component={ResetPassword} />
        <PrivateRouter exact path="/UserProfile" component={UserProfile} />
        <PrivateRouter exact path="/Users" component={User} />
        <PrivateRouter exact path="/Customers" component={CustomerAccount}/>
        <PrivateRouter exact path="/TaxManagement" component={TaxConfig} />
        <PrivateRouter exact path="/Venues" component={VenueCreations}/>

        <Route exact path='/' component={Login} />   
        <Route path='/404Page' component={Page404}/>
       
        <Route exact path="/Login" component={Login} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <PrivateRouter exact path="/ChangePassword" component={ChangePassword}/>
        <PrivateRouter exact path="/Events" component={EventList} />
        <PrivateRouter path="/EventConfiguration/:eventId?" component={EventForm} />
        <PrivateRouter exact path="/Upsells" component={UpsellList} />
        <PrivateRouter exact path="/UpsellConfiguration" component={UpsellCreation} />
        <PrivateRouter exact path="/EventDashboard" component={DefaultDashboard} />
        <PrivateRouter exact path="/ReportingDashboard" component={DashboardReport} />
        <PrivateRouter exact path="/Orders" component={Order} />
        <PrivateRouter exact path="/OrderDetails/:eventTicketDetailId" component={OrderDetails} />
        <PrivateRouter exact path="/ExchangeTicket/:eventTicketDetailId/:eventid/:eventdetailid" component={ExchangeTicket} />
        <PrivateRouter exact path="/Branding" component={Branding} />
        <PrivateRouter exact path="/TicketTemplate" component={TicketTemplate} />
        <PrivateRouter exact path="/EmailTemplate" component={EmailTemplate} />
        <PrivateRouter exact path="/ClientApi" component={ClientApi} />
        <PrivateRouter exact path='/QrValidation' component={QrValidation}/>
        <PrivateRouter exact path='/PromoCodes' component={PromoCodeList}/>
        <PrivateRouter exact path='/PromocodeConfiguration/:id?/:promoname?' component={PromoCode}/>
        <PrivateRouter exact path='/Allocations' component={AllotmentList} />
        <PrivateRouter exact path='/AllotmentConfiguration' component={Allotment} />
        <PrivateRouter exact path='/PerformanceView/:eventOccurrenceId' component={PerformanceView} />
        <PrivateRouter exact path='/TicketTypes' component={TicketTypes} />        
        <PrivateRouter exact path='/TicketTypeConfiguration/:accountId' component={TicketTypeConfiguration} />
        
        <PrivateRouter exact path='/Reports' component={ReportViewer} />
      
        <Route exact path='*' component={Page404}/> 
      </Switch>
    </Router>
  );
};
export default Routing;
