import axios from "axios";
import Cookies from 'universal-cookie';

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;
export async function signIn(credentials) {
 
  return await
    axios
      .post(apiUrl + "/auth/login", credentials)
      .then((response) => {        
          const cookies = new Cookies();
          cookies.remove('ExchangeTicket');
          cookies.remove('eventaddedit'); 
          cookies.remove('currentUser');  
          cookies.set('currentUser', {
            userid: response.data.user.userid, roleid: response.data.user.roleid,
            accountid: response.data.user.accountid, token: response.data.tokens.access.token,
            firstName: response.data.user.firstname, lastName: response.data.user.lastname,
            customdateformat: response.data.user.customdateformat
           },
           { maxAge: Number(process.env.REACT_APP_ACCESS_EXPIRED || 1209600) });
          return response.data;
      })
      .catch((error) => {
        throw error
      });

}

export async function signUp(credentials) {
  return await
    axios
      .post(apiUrl + "/auth/sign-up", credentials)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error
      });
}

export async function resetPassword (userid) {
  return await
    axios
      .post(apiUrl + "/auth/reset-password",userid)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error
      });
}


export async function Validatereset (credentials) {
  return await
    axios
      .post(apiUrl + "/auth/validate-token", credentials)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error
      });
}

export async function forgotActionPassword(credentials) {
  return await
    axios
      .post(apiUrl + "/auth/forgot-password", credentials)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error
      });
}
  export default {resetPassword,Validatereset,forgotActionPassword};