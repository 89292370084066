import React, { useState, useEffect } from "react";
import { getBookingDetails, individualUpsellReedem } from "../../services/eventService";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import Notification from "../Notification";

const QrUpsell = (props) => {
  const event = props.event;
  const [eventDetails, setEventDetails] = useState([]);
  const [openUpsell, setOpenUpsell] = useState(0);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  useEffect(() => {
    QrUpsell();
  }, []);
  const QrUpsell = () => {
    getBookingDetails({ paymentIntentId: event.paymentid, List: 'upsell list', eventticketdetail_id: event.eventticketdetailid })
      .then((res) => {
        setEventDetails(res[1]);
        setOpenUpsell(event.upsellflag);
      })
      .catch((error) => {
        const messages = error.response && !error.response.data.message ? error.response.data : error.message;
        notification(messages, "error");
      });
  }
  const upsellReedem = (item) => {
    const data = {
      "action_type": "individual upsell validation",
      "upsellpayment_id": item.paymentid,
      "eventticketdetail_id": item.eventticketdetailid,
      "individualupsellid": item.individualupsellid,
      "upsell_status": 1
    }
    individualUpsellReedem(data).then((res) => {
      QrUpsell();
      notification("Redeemed Successfully", "success");

    })
      .catch((error) => {
        const messages = error.response && !error.response.data.message ? error.response.data : error.message;
        notification(messages, "error");
      });
  }
  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }
  return (
    <div className="scrollbar" style={{ minHeight: 'auto', maxHeight: '250px', overflow: 'auto' }}>
      <Table className="qr-tbl">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Variant Name</TableCell>
            <TableCell className="text-center">Count</TableCell>
            <TableCell className="text-center">Cost</TableCell>
            <TableCell>Redeem</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {openUpsell > 0 ? (
            eventDetails.filter(x => x.upsellstatus !== 2)
              .map((upsell, index) => (
                <TableRow key={index}>
                  <TableCell>{upsell.upsellname}</TableCell>
                  <TableCell>{upsell.variantname||"No variant"}</TableCell>
                  <TableCell className="text-center">{upsell.rownumber}/{upsell.noofupsell}</TableCell>
                  <TableCell className="text-center">{event.currencysymbol}{upsell.price}</TableCell>
                  <TableCell>{upsell.upsellstatus === 0 ? <Button size="small" className="btn-redoutline py-0" onClick={() => upsellReedem(upsell)} >Redeem</Button> : <Button size="small" variant="outlined" className="link-gray py-0" disabled>Redeemed</Button>}</TableCell>
                </TableRow>
              ))
          ) : (<TableRow><TableCell className="text-warning">No upsell found</TableCell></TableRow>)}
        </TableBody>
      </Table>
      <Notification
        notify={notify}
        setNotify={setNotify}
      />
    </div>
  )
}

export default QrUpsell;