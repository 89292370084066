import React from "react";
import LaunchIcon from '@material-ui/icons/Launch';
import {
  Box, 
  Chip,
  Typography,
  Tooltip,
  Zoom,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Popup from "../../components/Popup";
import TicketDetail from "./TicketDetail";
import {initWidget} from '../../helper/Widget';

const Stats = ({ eventTitle, status, revenue, customerdateformat, soldTickets,currencytype,id,eventdetailid,eventid,accountid,disable,event_subcategory,reservedTickets,...props }) => {
  const history = useHistory();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [eventValue, setEventValue] = React.useState("");
  const [eventDetailValue, setEventDetailValue] = React.useState("");
  const modals=()=>{
    setOpenPopup(true); 
    setEventValue(eventid)
    setEventDetailValue(eventdetailid)
  }

  const openWidget = ()=>{

    initWidget(accountid,eventid,eventdetailid);
        props.setPopup && props.setPopup(false)
  }

  return (
   <div>
         <Box className="item flex">
            <Box variant="h5" sx={{flexGrow: 1}} className="event-title"> {eventTitle}{event_subcategory ? `: ${event_subcategory}` : ''}
            {disable ? <Tooltip onClick={openWidget} TransitionComponent={Zoom} title="Walk-In Sales" arrow><LaunchIcon className="launch-icon" /></Tooltip> :
            <Tooltip  TransitionComponent={Zoom} title="Event Ended" arrow><LaunchIcon className="launch-icon disabled" /></Tooltip> }
            </Box> 
            {status?<Box className="chip"><Chip label='Active' variant="outlined" size="small" className="success-outline"></Chip></Box> : 
            <Box className="chip"><Chip label='Off-Sale' variant="outlined" size="small" className="bg-danger"></Chip></Box> }          
          </Box> 
        
        <Typography variant="h6" mb={3}>
          <Box className="item flex">
            <Box fontWeight="fontWeightRegular" className="price"><span>{currencytype==='USD'?'$':'£'}</span>{revenue}</Box> 
            <Box className="date fSize-12 ml-2" sx={{flexGrow: 1}} >Revenue</Box> 
            <Box fontSize="small" sx={{m: 1}} className="date mr-0">{customerdateformat}</Box>
          </Box>
        </Typography>
        <Typography variant="subtitle2" mb={4} color="textSecondary">
          <Box className="item flex">
            <Box className="sold-ticket">{soldTickets} </Box>
            <Box className="sold fSize-12 ml-2" sx={{flexGrow: 1}} >Sold Tickets</Box> 
          </Box>
          {
            reservedTickets ? 
            <Box className="item flex">
              <Box className="sold-ticket">{reservedTickets} </Box>
              <Box className="sold fSize-12 ml-2" sx={{flexGrow: 1}} >Reserved Tickets </Box> 
            </Box> : null
          }
     
          <Box className="item flex">
            <Box className="p-1"><Button size="small" className="btn-redoutline py-0" onClick={modals}> Buyer&nbsp;List </Button></Box> 
            <Box className="p-1"><Button size="small" className="btn-redoutline py-0" onClick={()=>{history.push('/QrValidation')}}> Scanner </Button></Box> 
            <Box className="p-1">
              <Button size="small" className="btn-redoutline py-0" 
                onClick={() => history.push(`/PerformanceView/${eventdetailid}`)}>Performance View</Button>
            </Box>
          </Box>
        </Typography>
        <Popup title={`${eventTitle}-Buyer List`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <TicketDetail eventID={eventValue} eventDetailID={eventDetailValue} eventTitle={eventTitle} dateTime={customerdateformat}/>
      </Popup>
        </div>  
  );
};

export default Stats;
