import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import accounting from 'accounting';
import { Grid, Box, Card, Link, Divider, makeStyles, Breadcrumbs, Typography, FormControlLabel, Checkbox, FormControl, FormGroup, CircularProgress } from '@material-ui/core';
import Button from '../../components/controls/Button';
import { Helmet } from "react-helmet";
import Footer from "../../components/layouts/Footer";
import orderService from "../../services/orderService";
import { GetAppOutlined, Telegram } from '@material-ui/icons';
import jsPDF from "jspdf";
import "jspdf-autotable";
import Notification from '../../components/Notification';
import { universaldate } from '../../utils/dateFormat';
import OrderEmailDialog from "./OrderEmailDialog";
import OrderCommentsDialog from "./OrderCommentsDialog";
import { getOrderAction } from '../../redux/actions/orderActions';
import moment from 'moment';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Popup from "../../components/Popup";
import PartialRefund from './PartialRefund';
import { initWidget } from '../../helper/Widget';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem
} from "@material-ui/core";
import StripePaymentForm from '../events/StripePaymentForm';
import Controls from '../../components/controls/Controls';
import PaymentModalLink from './PaymentModalLink';
import { checkBookingDetails, checkBookingDetailsindividual, individualUpsellReedem } from "../../services/eventService";
import Cookies from 'universal-cookie';
import { getIndividualList, } from '../../services/clientApiServices';

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  breadcrumbs: {
    margin: '0px !important',
    padding: '12px 16px 0px 16px !important',
  },
  content: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,

  select: {
    width: '100% !important',
  },
}));

const drawerWidth = 250;


const ExchangePaymentForm = ({h, currencysymbol, stripePromise}) => {
  const [showPaymentForm, setShowPaymentForm] = useState(false)
  return (
    <>
      <Button size="small" text="Complete Payment" onClick={() => { setShowPaymentForm(true); }}></Button>
      <Popup
        title="Pay"
        openPopup={showPaymentForm}
        setOpenPopup={setShowPaymentForm}
      >
        <Elements options={{ theme: 'stripe', clientSecret: h.paymentid_secret }} stripe={stripePromise}>
          <StripePaymentForm clientSecret={h.paymentid_secret} currency={currencysymbol}></StripePaymentForm>
        </Elements>
      </Popup>
    </>

  )
}



const OrderDetails = () => {
  const history = useHistory();
  const classes = useStyles();

  
  const { eventTicketDetailId } = useParams();

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [openPopup, setOpenPopup] = useState(false);
  const [data, setData] = useState({})
  const [upsells, setUpsells] = useState([])
  const [showButton, setShowButton] = useState(true);
  const [resendEmailButton, setResendEmailButton] = useState(true);
  const [checkedOrder, setCheckedOrder] = useState(data?.marketing_optin === "True");
  const [openRefundPopup, setOpenRefundPopup] = useState(false);
  const [openCancelReservation, setOpenCancelReservation] = useState(false);
  const [wholePaymentid, setWholePaymentid] = useState("");
  const [isRefundSubmitting, setIsRefundSubmitting] = useState(false);
  const [cancelOrderInProgress, setCancelOrderInProgress] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [openUrlPopup, setOpenUrlPopup] = useState(false);
  const [resendEmailurl, setResendEmailurl] = useState(true);
  const [clientAPIList, setClientAPIList] = useState([]);
  const ticketDetails = data && Object.keys(data).length > 0 ? data.ticketdetails : [];
  const [selectedClient, setSelectedClient] = useState(null);
  const [showClientDropdown, setShowClientDropdown] = useState(false)
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');

 const checkParam = (reqParams, param_key) => {
    return (reqParams.includes(param_key));
  };

  const errorHandle = (error) => {
    if (!error && !checkParam(Object.keys(error))) {
      notification(error, 'error');
    } else {
      const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
      messages === "Invalid Token" ? timeOutSet() : notification(messages, 'error');
    }
  }
  useEffect(() => {
    OrderDetail(); //always get data
  }, [])

  const dispatch = useDispatch();


  const OrderDetail = async () => {
    const order = { account_id: '', email_id: '', eventticketdetail_id: eventTicketDetailId };
    orderService.getOrderDetails(order).then((res) => {
      let getPaymentid = "";
      if (res.data.eventinfo[0]?.eventticketUpsellmappings?.length > 0) {
        const value = res.data.eventinfo[0];
          res.data.eventinfo[0].eventticketUpsellmappings.map(y => {
            if (Number(y.upsellonly) === 1 && y.paymentstatus !== "Refund") {
              res.data.eventinfo[0].total = Number(value.total) + Number(y.total);
              const individualTax = Number(y.total) > 0 ? Number(y.tax) : 0;
              res.data.eventinfo[0].tax = Number(value.tax) + Number(individualTax);
              res.data.eventinfo[0].upsellprice = Number(value.upsellprice) + (Number(y.total) - individualTax);
              res.data.eventinfo[0].upselltaxcost = Number(value.upselltaxcost) + individualTax;
              if (!getPaymentid.includes(y.upsellpaymentid))
                getPaymentid += ', ' + y.upsellpaymentid;
            }
          })
      }
      setWholePaymentid(getPaymentid);
      if (res.data.eventinfo[0]?.ticketdetails?.length > 0 && res.data.eventinfo[0]?.individualupselldetail?.length > 0) {
        res.data.eventinfo[0].ticketdetails.map(x => {
          res.data.eventinfo[0].individualupselldetail.map(y => {
            if (x.ticketid === y.ticketid && y.variantname) {
              x.variantname = y.variantname
            }
          })
        })
      }
      setData(res.data.eventinfo[0]);
      dispatch(getOrderAction(res.data.eventinfo[0]));
      setCheckedOrder(res.data.eventinfo[0].marketing_optin === "True")
      setUpsells(res.data.eventinfo[0]?.eventticketUpsellmappings);
      setShowButton(true);
      const orderhistory = res.data.eventinfo[0].orderhistory;
      try {
        const requiresStripe = orderhistory && orderhistory.filter(o => { return o.paymentstatus === 'In progress'});
        if (requiresStripe) {
          console.log('loading stripe');
          if (!process.env.REACT_APP_STRIPE_PK_KEY) {
            alert('System error: Please set Stripe Public Key');
          }
          setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PK_KEY, { stripeAccount: res.data.eventinfo[0].account.stripeid }));
        }  
      } catch (err) {
        setShowButton(true);
        console.error('Failure to load stripe for in progress exchanges');
      }
    })
      .catch((error) => {
        setShowButton(true);
        errorHandle(error)
      });

    try {
      const users = {
        action_type: "active list",
        account_id: currentUser.accountid
      }
      const clientListRes = await getIndividualList(users);
      setClientAPIList(clientListRes.data);
    } catch (error) {
      setShowButton(true);
      errorHandle(error)
    }
  }

  const refundOrder = () => {
    setShowButton(false);
    setIsRefundSubmitting(true)
    if(data.paymentid.includes('pi_ORD') || Number(data.total) > 0){
    const user = {
      "payment_intent": data.paymentid,
      "totalAmount": Number(data.total).toFixed(2),
    }
    orderService.refundOrder(user).then((res) => {
      notification(res.message, 'success')
      OrderDetail();
      setIsRefundSubmitting(false)
      setOpenRefundPopup(false)
    })
      .catch((error) => {
        errorHandle(error)
        setShowButton(true);
        setIsRefundSubmitting(false)
      });
    }else{
      notification("In Stripe Refund, Amount is greater than 0.","error");
    }
  }

  const cancelReservation = () => {
    setShowButton(false);
    setCancelOrderInProgress(true);

    orderService.cancelReservation(data.reservationId).then((res) => {
      notification('Reservation Canceled', 'success')
      OrderDetail();
      setCancelOrderInProgress(false)
      setOpenCancelReservation(false)
    })
      .catch((error) => {
        errorHandle(error)
        setShowButton(true);
        setCancelOrderInProgress(false)
      });
  }

  //like a cash sale
  const markReservationOfflinePurchased = () => {
    setShowButton(false);
    setCancelOrderInProgress(true);

    orderService.markReservationOfflinePurchased(data.reservationId, selectedClient).then((res) => {
      notification('Reservation Marked As Purchased', 'success')
      OrderDetail();
      setCancelOrderInProgress(false)
      setOpenCancelReservation(false)
      setShowClientDropdown(false);
    })
      .catch((error) => {
        errorHandle(error)
        setShowButton(true);
        setShowClientDropdown(false);
        setCancelOrderInProgress(false)
      });
  }

  const resendEmail = () => {
    // if (orderDetails.email) {
    //   data.email = orderDetails.email
    // }
    setResendEmailButton(false);
    let email = prompt('Please specify an email', data.email);
    if (email) {
      orderService.resendEmail(data, email).then((res) => {
        notification(res.message, 'success')
        setResendEmailButton(true);
      })
        .catch((error) => {
          errorHandle(error)
          setResendEmailButton(true);
        });
    } else {
      errorHandle(new Error('Please specify an email address.'));
      setResendEmailButton(true);
    }
  }


  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }


  const handleChange = (e) => {
    setCheckedOrder(e.target.checked)
    const user = {
      action_type: "updation",
      payment_id: data.paymentid,
      marketingoptin: ((e.target.checked).toString()).replace('true', 'True').replace('false', 'False')
    }
    orderService.updateMarketingOptin(user).then((res) => {

      notification('Updated Successfully', 'success')

    })
      .catch((error) => {
        errorHandle(error)
      });
  }


  const payment = (nooftickets, redeemedticket) => {
    if ((redeemedticket / nooftickets) === 0) {
      return "Success";
    }
    else if (nooftickets === redeemedticket) {
      return "Scanned";
    } else {
      return "Partially Scanned";
    }
  }


  const exportPDF = () => {
    const upselllist = upsells.length > 0 ? upsells.map((e) => e.upsell?.upsellname) : ("No Upsells")

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 20;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Order Details";
    const headers = [[
      "Name",
      "Email",
      "EventName",
      "Date & Time",
      "Venue",
      "Order Total",
      "No Of Tickets",
      "Date and Time of Purchase",
      "Upsells",
      "Order Status"]];

    const datavalue = [[
      data.endusername,
      data.email,
      data.eventname,
      universaldate(data.eventdatetime),
      data.venuename,
      Number(data.total).toFixed(2),
      data.nooftickets,
      data.insertedtime,
      upselllist,
      data.paymentstatus

    ]]


    let content = {
      headStyles: { fillColor: [241, 25, 20], margin: { top: 10 } },
      theme: 'grid',
      styles: { lineColor: [0, 0, 0], minCellWidth: 10 },
      head: headers,
      body: datavalue
    };


    doc.text(title, marginLeft, 20);
    doc.autoTable(content);
    doc.save(`OrderDetails_${eventTicketDetailId}.pdf`)
  }

  const timeOutSet = () => {
    notification("Your Session Expired....Try again...", "error");
    setTimeout(() => {
      history.push('/');
    }, 2000);
  }

  const partialRefundPopup = (value,status) => {
    if(status !== 'error'){
   notification(value,status);
   setOpenPopup(false);
   OrderDetail();
   }else{
   const errorMessage = (value.response && value.response.data?.message) ? value.response.data.message : value.message;
   notification(errorMessage,status);
   }
  }

  const cancelButton =()=>{
    setOpenUrlPopup(false);
  }

  const addUpsell = () => {
    initWidget(data.accountid, data.eventticketdetailid, '', { eventParentId: data.eventid, eventdetailid: data.eventdetailid, eventticketdetailid: data.eventticketdetailid });
  }

  const validateTicket = async (item, ticketstatus) => {
    try {
      const ticketValidate = {
        "action_type": "individual ticket validation",
        "payment_id": data.paymentid,
        "eventticketdetail_id": data.eventticketdetailid,
        "ticket_id": item.ticketid,
        "ticket_status": ticketstatus ? ticketstatus : "1",
      }
      const response = await checkBookingDetailsindividual(ticketValidate);
      OrderDetail(response);
      notification("Ticket Validate Successfully", "success");
    } catch (error) {
      errorHandle(error);
    }
  }

  const upsellReedem = async (individualdata) => {
    try {
      const dataToRedeem = {
        "action_type": "individual upsell validation",
        "upsellpayment_id": individualdata.paymentid,
        "eventticketdetail_id": data.eventticketdetailid,
        "individualupsellid": individualdata.individualupsellid,
        "upsell_status": 1
      }
      const response = await individualUpsellReedem(dataToRedeem);
      OrderDetail(response);
      notification("Redeemed Successfully", "success");
    } catch (error) {
      errorHandle(error);
    }
  }

  return (
    <div >
      <Helmet title="Order Details" />
      <main className={classes.content}>
        <Typography variant="h5" gutterBottom className={classes.breadcrumbs}>
          Order Details
        </Typography>

        <Breadcrumbs aria-label="Breadcrumb" mt={2} className="breadcrumbs-level2">
          <Link component={NavLink} exact to="/Orders" underline="hover" color="inherit">
            Orders
          </Link>
          <Typography color="textPrimary">Order Details</Typography>
        </Breadcrumbs>
        <Button size="medium" text="Export" variant="outlined" className="btn-export btn-redoutline" startIcon={<GetAppOutlined />} onClick={exportPDF} >
          Export
        </Button>
        <Divider className="my-2" />
        <div className="pageContent">
          <Card>
            <Grid container spacing={4} className="ticket-details">
              <Grid item lg={6} xs={12} className="order-list border-right">
                <Box>
                  <span className="text-gray">Name</span>
                  <h6><strong>{data.endusername}</strong></h6>
                </Box>
                <Box>
                  <OrderEmailDialog localStateOrder={data} />
                  {
                    data.paymentstatus === "Success" && <Button size="small" 
                    variant="contained" endIcon={resendEmailButton? <Telegram /> : <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
                    disabled={!resendEmailButton} text="Resend Order Email"
                     className="link-red text-white py-0" onClick={() => resendEmail(data)} />
                  }
                </Box>
                <Box>
                { 
                    data.paymentstatus === "Progress" && 
                     <Button 
                      size="small" 
                      endIcon={resendEmailurl? <Telegram /> : <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
                      disabled={!resendEmailurl || !data.email}
                      text="Send Purchase Link"
                      className="link-red text-white py-0" 
                      onClick={()=>setOpenUrlPopup(true)} />   
                 }
                </Box>
                <Box>
                  <span className="text-gray">Phone</span>
                  <h6><strong>{data.phonenumber}</strong></h6>
                </Box>

                <Box>
                  <span className="text-gray">Payment ID</span>
                  <h6><strong>{data.paymentid+wholePaymentid}</strong></h6>
                </Box>
                <Box>
                  <span className="text-gray">Event Name</span>
                  <h6><strong>{data.eventname} {data.subcategory || ''}</strong></h6>
                </Box>
                <Box>
                  <span className="text-gray">Event Date and Time</span>
                  <h6><strong>{universaldate(data.eventdatetime)}</strong></h6>
                </Box>
                <Box>
                  <span className="text-gray">Venue</span>
                  <h6><strong>{data.venuename}</strong></h6>
                </Box>
                <Box>
                <span className="text-gray">Add New Upsell</span> <br/>
                  { data.paymentstatus === "Success" ?  <Button
                    onClick={() => addUpsell()}
                    size="medium"
                    text="Add Upsell"
                    className="btn-redoutline py-1"
                  > Add Upsell </Button> : <Button size="small" text="Add Upsell" className="link-gray py-0" disabled />}
                </Box><br/>
                <Box>
                  <OrderCommentsDialog localStateOrder={data} />
                </Box>
              </Grid>
              <Grid item lg={6} xs={12} className="order-list border-right">
                <Box>
                  <span className="text-gray">Amount Paid</span>
                  <h6><strong>{accounting.formatMoney(data.total, data.currencysymbol)}</strong></h6>
                </Box>
  

              {data.tax &&
                  <Box>
                    <span className="text-gray">Total Tax</span>
                    <h6><strong>{accounting.formatMoney(data.tax, data.currencysymbol)}</strong></h6>
                  </Box>
                }  
                {data.applicableFee &&
                  <Box>
                    <span className="text-gray">Booking Fee</span>
                    <h6><strong>{accounting.formatMoney(data.applicableFee, data.currencysymbol)}</strong></h6>
                  </Box>
                }        

                {data.eventprice &&
                  <Box>
                    <span className="text-gray">Ticket Subtotal</span>
                    <h6><strong>{accounting.formatMoney(data.eventprice, data.currencysymbol)}</strong></h6>
                  </Box>
                }  

              {data.eventtaxcost &&
                  <Box>
                    <span className="text-gray">Ticket Tax</span>
                    <h6><strong>{accounting.formatMoney(data.eventtaxcost > 0 ? data.eventtaxcost : 0, data.currencysymbol)}</strong></h6>
                  </Box>
                }  

              {data.upsellprice &&
                  <Box>
                    <span className="text-gray">Upsell Subtotal</span>
                    <h6><strong>{accounting.formatMoney(data.upsellprice, data.currencysymbol)}</strong></h6>
                  </Box>
                }  
              {data.upselltaxcost &&
                  <Box>
                    <span className="text-gray">Upsell Tax</span>
                    <h6><strong>{accounting.formatMoney(data.upselltaxcost, data.currencysymbol)}</strong></h6>
                  </Box>
                }                  

              {data.promocode &&
                  <Box>
                    <span className="text-gray">Promo Code</span>
                    <h6><strong>{data.promocode}</strong></h6>
                  </Box>
                }

                {data.promodiscount &&
                  <Box>
                    <span className="text-gray">Discount Amount</span>
                    <h6><strong>{accounting.formatMoney(data.promodiscount, data.currencysymbol)}</strong></h6>
                  </Box>
                }
                <Box>
                  <span className="text-gray">No of Tickets</span>
                  <h6><strong>{data.nooftickets}</strong></h6>
                </Box>
                {ticketDetails?.length > 0 && <>
                  <span className="text-gray">Ticket Details</span>
                  <Grid container style={{marginBottom: '4px'}}>
                    <Grid item xs={8}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Ticket ID</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Ticket Type</TableCell>
                            <TableCell>Ticket Validate</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ticketDetails.length > 0 ? (ticketDetails.map((ticket) => (
                            <TableRow key={ticket.ticketid}>
                              <TableCell>{ticket.ticketid}</TableCell>
                              <TableCell>{ticket.ticketprice}</TableCell>
                              <TableCell>{ticket?.ticket_type?.name ? ticket.ticket_type.name : "Normal Ticket"}</TableCell>
                              <TableCell>{ticket.ticketstatus === 0 ? <Button size="small" className="btn-redoutline py-0" text="Validate" onClick={() => { data.paymentstatus === "Success" ? validateTicket(ticket) : notification(`This Order is ${data.paymentstatus}.`,"error")}} >Validate</Button> : <Button size="small" variant="outlined" className="link-gray py-0" text="Reset" onClick={() => { data.paymentstatus === "Success" ? validateTicket(ticket, "0") : notification(`This Order is ${data.paymentstatus}.`,"error")}}>Reset</Button>}</TableCell>
                            </TableRow>
                          ))) : (<Box>
                            <h6 className="text-warning"><strong>No ticket details found</strong></h6>
                          </Box>)}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </>}
                <Box>
                  <span className="text-gray">Channel</span>
                  <h6><strong>{data.channelname}</strong></h6>
                </Box>
                {data.eventpaymentallocationMappings?.length ?
                  <Box>
                    <span className="text-gray">Allocation</span>
                    {data.eventpaymentallocationMappings.map(a => <h6><strong>{a.allocation?.allocationname}</strong></h6>)}
                  </Box> : null
                }

                {
                  data.client?.clientname ?
                    <Box>
                      <span className="text-gray">API Client</span>
                      <h6><strong>{data.client.clientname}</strong></h6>
                    </Box> : null
                }
                {
                  data.playerteamMappings?.length ?
                    <Box>
                      <span className="text-gray">Players</span>
                      <h6><strong>{data.playerteamMappings?.map(p => {
                        return (
                          <div>{p.team?.teamname}: Player {p.playerid} { p?.paymentteammap?.allocation != null ? `(Allocation: ${p?.paymentteammap?.allocation?.allocationname})` : null} </div>
                        )
                      })}</strong></h6>
                    </Box> : null

                }


                <Box>
                  <span className="text-gray">Date and Time of Purchase </span>
                  <h6><strong>{data.insertedtime}</strong></h6>
                </Box>
                {data?.individualupselldetail?.length > 0 &&
                  <>
                    <span className="text-gray">Upsells </span>
                    <Grid container>
                      <Grid item xs={8}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Variant Name</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Redeem</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>


                            {data.individualupselldetail.length > 0 ? (data.individualupselldetail.map((upsell, index) => (
                              <TableRow key={`${upsell.upsellid}-${index}`}>
                                <TableCell>{upsell.upsell?.upsellname}</TableCell>
                                <TableCell>{upsell.variantname||"No Variant"}</TableCell>
                                <TableCell>{Number(upsell.individualprice)+Number(upsell.individualtax)}</TableCell>
                                <TableCell>{upsell.upsellstatus === 0 ? <Button size="small" className="btn-redoutline py-0" text="Redeem" onClick={() => { data.paymentstatus === "Success" ? upsellReedem(upsell) : notification(`This Order is ${data.paymentstatus}.`,"error")}} >Redeem</Button> : <Button size="small" variant="outlined" className="link-gray py-0" text="Redeemed" disabled>Redeemed</Button>}</TableCell>
                              </TableRow>


                            )))


                              : (<Box>

                                <h6 className="text-warning"><strong>No upsell found</strong></h6>
                              </Box>)}

                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </>
                }


                <Box>

                  <span className="text-gray">Order Status </span>
                  {data.refundstatus === "Inprogress" ? <h6 className="text-danger"><strong>Refund status in Pending</strong></h6>   :
                  data.paymentstatus === "Refund" ? <h6 className="text-success"><strong>Refund</strong></h6> :
                  data.paymentstatus === "Progress" ? <h6 className="text-warning"><strong>Progress</strong></h6> :
                    data.paymentstatus === "Failure" ? <h6 className="text-danger"><strong>Failure</strong></h6> :
                      <h6 className={(data.redeemedticket / data.nooftickets) === 0 ? "text-success" : "text-danger"}><strong>{payment(data.nooftickets, data.redeemedticket)}</strong></h6>}


                </Box>
                {
                  data.reservationId ?
                  <Box>
                    <span className="text-gray">Reservation Id</span>
                    <h6><strong>{data.reservationId}</strong></h6>
                    {
                      data.paymentstatus === 'Progress' && data.reservationId &&
                      <Button size="small" text="Edit/Checkout Reservation" onClick={()=>{
                        history.push('/Orders');
                        initWidget(data.accountid,data.eventid,data.eventdetailid,{},"",data.reservationId)}
                      } className="link-red text-white py-0"  />
                    }
                    {
                      data.paymentstatus === 'Progress' && data.reservationId && 
                      <Button className="link-red text-white py-0"  size="small" text="Cancel Reservation" onClick={()=>{ setOpenCancelReservation(true)}}>Cancel Reservation</Button>
                    }

                    {
                      data.paymentstatus === 'Progress' && data.reservationId && 
                      <Button className="link-red text-white py-0"  size="small" text="Mark Purchased (Cash Sale)" onClick={()=>{
                        if (clientAPIList.length > 0) {
                          setShowClientDropdown(true);
                        } else {
                          markReservationOfflinePurchased()
                        }
                      }}>Mark Offline Purchased</Button>
                    }                    

                  </Box> : null                      
                }
   
                {
                  data.orderhistory &&
                  <Box>
                    <span className="text-gray">Order History</span>

                    {
                      data.orderhistory?.map(h => {
                        switch (h.action_type) {
                          case 'exchange':
                            return (<li key={h.id}>
                              {moment(h.insertedtime).format('MM-DD-YYYY hh:mm a')} ({h.action_type}): {h.from_event_name} {h.from_players} to {h.to_event_name} {h.to_players} {h.description}
                              {h.paymentstatus === 'In progress' && h.paymentid_secret && stripePromise ?
                                <ExchangePaymentForm h={h} currencysymbol={data.currencysymbol} stripePromise={stripePromise}></ExchangePaymentForm> : null}
                            </li>);
                          case 'refund':
                            return <li key={h.id}> {moment(h.insertedtime).format('MM-DD-YYYY hh:mm a')} ({h.action_type}): {h.description} PaymentId: {h.paymentid}</li>;
                          default:
                            return <li key={h.id}> {moment(h.insertedtime).format('MM-DD-YYYY hh:mm a')} ({h.action_type}): {h.from_event_name} {h.eventdatetime} {h.description}</li>;
                        }
                      })
                    }
                  </Box>
                }

                <Box>
                  {!(`${data.endusername}`).toLowerCase().includes('rem_') ?

                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkedOrder} onChange={handleChange} name="email" />

                          } label="Email Marketing"
                        />
                      </FormGroup>
                    </FormControl>
                    :
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox checked={checkedOrder} disabled name="email" />

                          } label="Email Marketing"
                        />
                      </FormGroup>
                    </FormControl>
                  }

                </Box>
                {(data.refundstatus !== "Inprogress") && !data.refund && (data.paymentstatus === "Success") && Number(data.recordstatus) !== 2 ?
                  <>  
                    {data?.paymentcount === 1 ? <Button 
                      size="small" 
                      text="Refund"
                      className="link-red text-white py-0" 
                      onClick={() => setOpenRefundPopup(true)}
                    /> : null}
                      {((data?.paymentcount > 1 || data.nooftickets > 1) || data.individualupselldetail.length > 0) && showButton ? 
                        <Button size="small" text="Partial Refund" className="link-gray py-0" onClick={()=>{setOpenPopup(true)}}/> : null} 
                  </> : <Button size="small" text="Refund" className="link-gray py-0" disabled />}

              </Grid>
            </Grid>

          </Card>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
        <Popup title="Partial Refund" openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <PartialRefund
            setOpenPopup={setOpenPopup}
            popupClose={partialRefundPopup}
            withTeam={data?.playerteamMappings && data?.playerteamMappings.length > 0 ? true : false}
            teamData={data?.playerteamMappings && data?.playerteamMappings.length > 0 ? data.playerteamMappings : []}
            teamMapping={data?.paymentteamMapping && data?.paymentteamMapping.length > 0 ? data.paymentteamMapping : []}
            ticketdetails={data?.ticketdetails && data?.ticketdetails.length > 0 ? data.ticketdetails : []}
            eventDetails={{ occurrenceid: data?.eventdetailid, eventid: data?.eventid, paymentid: data?.paymentid,
            accountid: data?.accountid, eventticketdetailid: eventTicketDetailId, eventname: data?.eventname, eventdatetime: data?.eventdatetime  }}
            nooftickets={data?.nooftickets}
            currencySymbol={data?.currencysymbol}
            paymentcount={data?.paymentcount}
            individualupselldetail={data?.individualupselldetail && data?.individualupselldetail.length > 0 ? data?.individualupselldetail:[] }
          />
        </Popup> 
        <Popup title="Enter Payment Modal Link" openPopup={openUrlPopup} setOpenPopup={setOpenUrlPopup}>
        <PaymentModalLink
          data={data}
          cancelButton={cancelButton}
          setResendEmailurl={setResendEmailurl}/> 
        </Popup> 
        <Popup title="Refund" openPopup={openRefundPopup} setOpenPopup={setOpenRefundPopup}>
          <>
            <strong>Are you sure you want to refund this whole order?</strong>
            <br/><br/>
            <Controls.Button 
              onClick={() => setOpenRefundPopup(false)} 
              size="small" 
              text="Cancel" 
              className="link-gray"
            />
            <Controls.Button 
              disabled={isRefundSubmitting} 
              onClick={() => refundOrder()} 
              color="primary" 
              size="small" 
              endIcon={isRefundSubmitting && <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
              text={isRefundSubmitting ? "Processing...": "Confirm"} autoFocus className="link-red" />
          </>
        </Popup> 

        <Popup title="Cancel Reservation" openPopup={openCancelReservation} setOpenPopup={setOpenCancelReservation}>
          <>
            <strong>Are you sure you want to cancel this reservation?</strong>
            <br/><br/>
            <Controls.Button 
              onClick={() => setOpenCancelReservation(false)} 
              size="small" 
              text="Cancel" 
              className="link-gray"
            />
            <Controls.Button 
              disabled={cancelOrderInProgress} 
              onClick={() => cancelReservation()} 
              color="primary" 
              size="small" 
              endIcon={cancelOrderInProgress && <CircularProgress size={20} color="white" style={{ marginLeft: '10px'}} />}
              text={cancelOrderInProgress ? "Canceling...": "Confirm"} autoFocus className="link-red" />
          </>
        </Popup>
        
        <Popup title="Mark Purchased" openPopup={showClientDropdown} setOpenPopup={setShowClientDropdown}>
          <>
            <strong>Select partner client:</strong>
            <br/>
            <Select
              label="Select a Client"
              id="client-select"
              style={{width: "240px"}}
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e.target.value);
              }}
            >
              {clientAPIList.map((client) => (
                <MenuItem key={client.clientid} value={client.clientid}>
                  {client.clientname}
                </MenuItem>
              ))}
            </Select>

            <Controls.Button 
              onClick={() => markReservationOfflinePurchased()}
              size="small" 
              text="Set Paid" 
              color="primary"
              className="link-red"
            />
          </>
        </Popup>
      </main>
      <Footer />
    </div>
  );
};

export default OrderDetails;